<template>
    <div>
        <div class="button-group clearfix" v-if="state === 'view'">
            <router-link :to="{ name: 'AdminTemplateEdit', params : { id : id } }" :title="text.edit" class="btn btn-primary">{{text.edit}}</router-link>
            <CopyAction :linkName="templateName" @actionCalled="copyTemplate(id, 'AdminTemplateEdit')" :classString="'btn btn-secondary'" />
            <ArchiveAction :linkName="templateName" @actionCalled="archiveTemplate(id, 'initTemplateView')" v-if="!isArchived" :classString="'btn btn-tertiary'" />
            <RestoreAction :linkName="templateName" @actionCalled="restoreTemplate(id, 'initTemplateView')" v-if="isArchived" :classString="'btn btn-tertiary'" />

            <router-link :to="{ name: 'AdminTemplateList' }" :title="text.back_previous" class="btn btn-frameless">{{text.back_previous}}</router-link>
        </div>

        <div class="columns">
            <div class="content">
                <div class="panel panel-body">
                    <Form v-model="templateFormData" ref="templateForm" />
                </div>
            </div>
        </div>

        <div class="button-group clearfix" v-if="state === 'edit'">
            <div class="button-group-left">
                <router-link :to="{ name: 'AdminTemplateEditContent' }" :title="text.back_previous" class="btn btn-frameless">{{text.back_previous}}</router-link>
            </div>
            <div class="button-group-right">
                <router-link :to="{ name: 'AdminTemplateList' }" :title="text.done" class="btn btn-primary">{{text.done}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import lang from '@/shared/lang';
    import {log} from '@/shared/utils';

    import {templateActionsMixin} from "@/views/mixins/templateActions";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import Form from "@/components/form/form";
    import CopyAction from "@/components/actionlinks/copy";
    import ArchiveAction from "@/components/actionlinks/archive";
    import RestoreAction from "@/components/actionlinks/restore";
    import {mapActions, mapState} from "vuex";

    export default {
        name: 'AdminTemplateView',
        components: {Form, CopyAction, ArchiveAction, RestoreAction},
        mixins: [templateActionsMixin, formWrapperMixin],
        data() {
            return {
                templateFormData: [],
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id ;
            },
            state() {
                return this.$route.params.state ? this.$route.params.state : "view";
            },
            templateName() {
                return this.getTemplateName();
            },
            isArchived() {
                return this.getTemplateArchived();
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async initTemplateView(){

                log(["Init template view", this.id]);
                await this.loadTemplateByID(this.id);

                // Set Page Title
                let nameStr = this.templateName;
                if(this.isArchived){
                    nameStr += " (" + this.text.archived + ")";
                }
                log(["Initting with name?", nameStr, this.isArchived, this.$route]);

                this.setPageTitleAction({ title : this.$route.meta.pageTitle.replace("%s%", nameStr) });

                this.buildTemplateViewForm();
            },
            buildTemplateViewForm(){
                log(["Building property form", this.templateFormData, this.templateData]);
                this.templateFormData = [];

                this.getTemplateProperties().forEach((value, index) => {
                    log(["Property", index, value[1]]);

                    if(value[1].excluded !== true){
                        this.templateFormData.push(this.buildFormStructureRow(index, value[1], "templateview"));
                    }
                    else
                    {
                        this.templateFormData.push(this.buildDisabledFormStructureRow(index, value[1]));
                    }
                });

                log(["Refresh form data", this.$refs['templateForm']]);
                this.$refs['templateForm'].refreshFormData()
            },
        },
        async mounted() {
            await this.initTemplateView();
        },
    }
</script>
