<template>
    <a href="#" :title="text.restore + ' ' + linkName" @click="onClick($event)" :class="classString">{{text.restore}}</a>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";

    export default {
        name: "RestoreAction",
        props: {
            linkName: {
                type: String
            },
            classString : {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            onClick(e) {
                e.preventDefault();

                log("Action fired");

                this.$emit('actionCalled')
            }
        },
    }
</script>