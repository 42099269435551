<template>
    <div>
        <PreventUnload :when="shouldPreventNavigation" :message="text.unsaved_navigation_confirm" />
        <div class="columns">
            <div class="content no-sidebar">
                <div class="panel panel-body">
                    <Form v-model="templateFormData" ref="templateForm" />
                </div>
            </div>
        </div>

        <div class="button-group clearfix">
            <div class="button-group-left">
                <router-link :to="{ name: 'AdminTemplateEdit' }" :title="text.back_previous" class="btn btn-frameless">{{text.back_previous}}</router-link>
            </div>
            <div class="button-group-right">
                <router-link :to="{ name: 'AdminTemplateList' }" :title="text.cancel" class="btn btn-tertiary btn-smyspace">{{text.cancel}}</router-link>
                <a href="#" :title="text.save" class="btn btn-secondary btn-smyspace" @click="save($event, false)" @keyup.enter="save($event, false)">{{text.save}}</a>
                <a href="#" :title="text.save_and_preview" class="btn btn-primary" @click="save($event, true)" @keyup.enter="save($event, true)">{{text.save_and_preview}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from '@/shared/lang';
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts"

    import {templateActionsMixin} from "@/views/mixins/templateActions";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import {mapActions} from "vuex";

    import Form from "@/components/form/form";
    import PreventUnload from 'vue-prevent-unload';

    export default {
        name: 'TemplateEditContent',
        components: {Form, PreventUnload},
        mixins: [templateActionsMixin, formWrapperMixin],
        data() {
            return {
                templateFormData: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id;
            },
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async initTemplateEdit(){
                log(["Mounted with existing template", this.id]);
                await this.loadTemplateByID(this.id);

                this.setPageTitleAction({ title : this.$route.meta.pageTitle.replace("%s%", this.templateData.name) });

                this.buildTemplateEditForm();
            },
            buildTemplateEditForm(){
                log(["Building property form", this.templateFormData, this.templateData]);
                this.templateFormData = [];

                this.getTemplateProperties().forEach((value, index) => {
                    log(["Property", index, value[1]]);

                    if(value[1].excluded !== true){
                        this.templateFormData.push(this.buildFormStructureRow(index, value[1], "template"));
                    }
                    else
                    {
                        this.templateFormData.push(this.buildDisabledFormStructureRow(index, value[1]));
                    }
                });

                log(["Refresh form data", this.$refs['templateForm']]);
                this.$refs['templateForm'].refreshFormData();
                this.setWatchFormRef("templateForm");
            },
            async save(e, navigateNextOnSave = false){
                e.preventDefault();
                log(["Saving new template set up", navigateNextOnSave, this.templateFormData, this.templateData]);

                if(this.$refs.templateForm.validateForm()){
                    log(["FORM OK"]);

                    this.templateFormData.forEach(element => {
                        log(["looping through save", element]);

                        // save default value
                        this.setTemplatePropertyValueByAlias(element.name, element.value);

                        // saving a locked value
                        this.setTemplatePropertyLockedByAlias(element.name, element.locked);

                        // saving a ignore options value
                        log("Checking ignoreOptions");
                        if(element.hasOwnProperty("ignoreOptions")){
                            log("Yes");
                            this.setTemplatePropertyIgnoreOptionsByAlias(element.name, element.ignoreOptions);
                        }

                        // saving option selected values
                        log("Checking element type");
                        if(element.type.includes("mediapicker")){
                            log(["Is media picker", element.selectedOptions ]);
                            this.setTemplatePropertySelectedMediaItemOptionsByAlias(element.name, element.selectedOptions);
                        }
                        else if(element.type.includes("picker")){
                            log(["Is text picker", element.selectedOptions ]);
                            this.setTemplatePropertySelectedOptionsByAlias(element.name, element.selectedOptions);
                        }
                    });

                    log(["Ready to save", this.templateData]);

                    // save down to the database
                    let databaseSaveOK = await this.saveTemplate();

                    if(databaseSaveOK){
                        this.$store.dispatch('addSystemMessageAction', {
                            'type' : MESSAGE_TYPE_SUCCESS,
                            'message' : lang.message_template_saved
                        });

                        this.$refs.templateForm.resetChanged();
                    }

                    if(databaseSaveOK && navigateNextOnSave){
                        this.$router.push( { name: 'AdminTemplateView', params : { id : this.getTemplateID(), state : "edit" } } );
                    }

                }
                else
                {
                    log(["FORM NOT OK"]);
                    this.scrollToFirstError();
                }
            }
        },
        async mounted() {
            this.initTemplateEdit();
        },
        watch:{
            async $route (){
                await this.initTemplateEdit();
            }
        },
    }
</script>