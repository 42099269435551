<template>
    <div>
        <div class="table-scroller">
            <table class="archive">
                <thead>
                <tr>
                    <th class="w-5/12">{{text.template_name}}</th>
                    <th class="w-3/12">{{text.business_unit}}</th>
                    <th class="w-2/12">{{text.date_archived}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="archiveTableData.length > 0">
                <tr v-for="row in archiveTableData" :key="row.id">
                    <td><router-link :to="{ name: 'AdminTemplateEdit', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                    <td class="table-meta">{{row.businessUnitName || text.all_business_units}}</td>
                    <td class="table-meta">{{row.lastEdited | formatDate}}</td>
                    <td>
                        <ViewAction :routeName="'AdminTemplateView'" :routeParams="{ 'id' : row.id }" :linkName="row.name" />
                        <CopyAction :linkName="row.name" @actionCalled="copyTemplate(row.id, 'AdminTemplateEdit')" />
                        <RestoreAction :linkName="row.name" @actionCalled="restoreTemplate(row.id, 'refreshArchiveTemplatesList')" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="4">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="archiveTablePagination.totalPages > 0" :pagination="archiveTablePagination" @go-previous="refreshArchiveTemplatesList" @go-next="refreshArchiveTemplatesList" />

    </div>
</template>

<script>
    import lang from "@/shared/lang";
    //import {log} from "@/shared/utils";
    //import {showDefaultServerErrorMessage} from "@/shared/utils";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {templateActionsMixin} from "@/views/mixins/templateActions";

    import Pagination from "@/components/pagination";
    import ViewAction from "@/components/actionlinks/view";
    import CopyAction from "@/components/actionlinks/copy";
    import RestoreAction from "@/components/actionlinks/restore";

    export default {
        name: 'TemplateArchive',
        components: {Pagination, ViewAction, CopyAction, RestoreAction},
        mixins: [formatContentMixin,templateActionsMixin],
        data() {
            return {
                archiveTableData: [],
                archiveTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            async refreshArchiveTemplatesList(page = 1){
                await this.loadArchiveTemplatesList(page);
            }
        },
        async mounted() {
            this.refreshArchiveTemplatesList(1);
        },
    }
</script>