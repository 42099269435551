<template>
    <div>
        <div class="table-scroller table-shadow">
            <table>
                <thead>
                <tr>
                    <th class="w-5/12">{{text.template_name}}</th>
                    <th class="w-3/12">{{text.business_unit}}</th>
                    <th class="w-2/12">{{text.date_last_modified}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="activeTableData.length > 0">
                <tr v-for="row in activeTableData" :key="row.id">
                    <td><router-link :to="{ name: 'AdminTemplateEdit', params: { id : row.id } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                    <td class="table-meta">{{row.businessUnitName || text.all_business_units}}</td>
                    <td class="table-meta">{{row.lastEdited | formatDate}}</td>
                    <td>
                        <ViewAction :routeName="'AdminTemplateView'" :routeParams="{ id : row.id }" :linkName="row.name" />
                        <EditAction :routeName="'AdminTemplateEdit'" :routeParams="{ id : row.id }" :linkName="row.name" />
                        <CopyAction :linkName="row.name" @actionCalled="copyTemplate(row.id, 'AdminTemplateEdit')" />
                        <ArchiveAction :linkName="row.name" @actionCalled="archiveTemplate(row.id, 'refreshActiveTemplatesList')" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="4">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="activeTablePagination.totalPages > 0" :pagination="activeTablePagination" @go-previous="refreshActiveTemplatesList" @go-next="refreshActiveTemplatesList" />

    </div>
</template>

<script>
    import lang from "@/shared/lang";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {templateActionsMixin} from "@/views/mixins/templateActions";

    import Pagination from "@/components/pagination";
    import EditAction from "@/components/actionlinks/edit";
    import ViewAction from "@/components/actionlinks/view";
    import CopyAction from "@/components/actionlinks/copy";
    import ArchiveAction from "@/components/actionlinks/archive";

    export default {
        name: 'TemplateLists',
        components: {Pagination, ViewAction, EditAction, CopyAction, ArchiveAction},
        mixins: [formatContentMixin,templateActionsMixin],
        data() {
            return {
                activeTableData: [],
                activeTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            async refreshActiveTemplatesList(page = 1){
                await this.loadActiveTemplatesList(page);
            }
        },
        async mounted() {
            this.refreshActiveTemplatesList(1);
        },
    }
</script>